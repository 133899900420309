@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding:0 ;
    margin: 0;
    text-decoration: none;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    overflow: hidden;
}.container{
    display: flex;
}
.sidebar{
    /* background-color: rgba(26, 126, 26, 0.811); */
    background-color:#2c89e0;
    color: white;
    height: 100vh;
    width: 250px;
}
.top_section{
    display: flex;
    align-items: center;
    margin: 20px 15px;
}
main{
    width: 100%;
    background-color: #f7fffd;
    overflow: overlay;
    height: 100vh;
    padding: 20px;
    display: block;
    align-items: center;
    justify-content: center;
 
}
.logo{
  font-size: 15px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 70px;
}
.link{
    display: flex;
    color: white;
    padding: 15px 20px;
   gap: 25px;
   transition: all 0.5s;
}
.link:hover{
   background-color: #fff;
    color: black;
    transition: all 0.5s;
}
.active{
    background-color: #f7fffd;
    color: black;
}
.icon , .link_text{
    font-size: 17px;
}

.row{
    max-width: 100% !important;
    width: 100% !important;
}

label{
    padding: 1% 0 !important;
    font-size: medium !important;
}

.btn-success {
    font-size: medium !important;
    text-align: center;
}
a{
    text-decoration: none !important;
}
.form-control{
    font-size: 14px !important;
}
.btn{
    padding: 1% !important;
    width: 6% !important;
}
input{
    height: 34px !important;
}
/* .btn-primary{
    padding: 1% !important;
    width: 5% !important;
} */

.edit {
    background-color: green;
    border-radius: 20px;
    padding: 5px;
    border: 2px solid green;
}

.delete {
    background-color: red;
    border-radius: 20px;
    padding: 5px;
    border: 2px solid red;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    margin: 0 auto;
  }
  .loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid rgb(233, 6, 93);
    animation: prixClipFix 2s linear infinite ;
  }
  .loader::after{
    border-color: #bf00ff;
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: 6px;
  }

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }


::-webkit-scrollbar {
    width: 4px; 
    background-color: rgb(241 173 179);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(241 173 179); 
    border-radius: 4px; 
  }
  
  ::-moz-scrollbar {
    width: 8px; 
  }
  
  ::-moz-scrollbar-thumb {
    background-color: rgb(241 173 179); /* Color of the scrollbar thumb */
    border-radius: 4px; /* Border radius of the scrollbar thumb */
  }

  .rdt_TableRow {
    background-color: #f7fffd !important;
  }

  .iWGkkM {
    background-color: #f7fffd !important;
  }

  .huwXap {
    background-color: #f7fffd !important;
  }

  .gSVZoG {
    background-color: #f7fffd !important;
  }
  .gUHoGv {
    background-color: #f7fffd !important;
  }
  .cOhUY{
    background-color: #f7fffd !important;
  }
  .rdt_TableHeader {
    background-color: #f7fffd !important;
  }
  .rdt_Pagination {
    background-color: #f7fffd !important;
  }
  button{
    font-size: 13px !important;
  }
  