.link-style {
  color: black;
  font-size: 16px;
  text-decoration: none;
  align-items: start !important;
  padding: 5px 10px;
  transition: color 0.3s, font-size 0.3s;
  transition: scale 0.3s;
}

/* Link style on hover */
.link-style:hover {
  color: #2c89e0;
  ;
  scale: 1.2;
  transition: scale 0.3s;
  cursor: pointer;
}
.dpxigv {
  height: 100px !important; 
}
.kpUUV  {
  min-height: 80px !important;
}
.iSiGgX{
  min-height: 80px !important;
}